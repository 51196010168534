<template>
  <div>
    <div class="d-sm-block d-mb-block d-lg-none" style="overflow-x: hidden">
      <Header2></Header2>
      <div class="distributor-container-mobile">
        <div class="category-header-mobile">
          <h1 class="category-title-mobile">
            {{ this.category.name }}
          </h1>
          <div class="search-component-mobile">
            <input
              type="search"
              class="search-component__input-mobile"
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
            <svg width="24" height="24" class="search-component__icon-mobile">
              <use href="../assets/icons/icons.svg#search-icon"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="items-container-mobile">
        <v-row>
          <v-col
            v-if="isLoading && filteredProducts.length === 0"
            cols="12"
            class="loading-message"
          >
            <v-progress-circular
              indeterminate
              size="40"
              color="primary"
            ></v-progress-circular>
          </v-col>
          <v-col
            v-else-if="filteredProducts.length === 0"
            cols="12"
            class="no-products-message"
          >
            <p>В данной категории отсутствуют товары.</p>
          </v-col>
          <v-col
            v-for="(item, index) in filteredProducts.filter(product => product.stateCode === 'Active' && product.categories.every(category => category.stateCode === 'Active'))"
            :key="index"
            cols="6"
            class="item-box-mobile"
            @click="enterProduct(item.id)"
          >
            <!-- Display item data here using item properties -->
            <img
            v-if="item.images && item.images.length > 0 && item.images.some(image => image.type === 'Original')"
             :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${item.images[0].id}&width=304&height=282&isKeepAspectRatio=true` "
              class="card-img-mobile"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/image-default.png"
              class="card-img-mobile"
              alt="Not loading"
            />
            <p class="item-name-mobile">{{ item.name }}</p>
            <p class="item-country-mobile">{{ item.origin }}</p>
          </v-col>
          <div class="horizontal-line"></div>
        </v-row>
      </div>
    </div>
    <div class="d-none d-lg-block d-xl-block d-xxl-block">
      <Header2></Header2>
      <div class="distributor-container">
        <div class="category-header">
          <h1 class="category-title">
            {{ this.category.name }}
          </h1>
          <div class="search-component">
            <input
              type="search"
              class="search-component__input"
              v-model="search"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
            <svg width="24" height="24" class="search-component__icon">
              <use href="../assets/icons/icons.svg#search-icon"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="items-container">
          <v-row>
            <v-col
          v-if="isLoading && filteredProducts.length === 0"
          cols="12"
          class="loading-message"
          >
          <v-progress-circular
            indeterminate
            size="40"
            color="primary"
          ></v-progress-circular>
        </v-col>
      <v-col
        v-else-if="filteredProducts.length === 0"
        cols="12"
        class="no-products-message"
      >
        <p>В данной категории отсутствуют товары.</p>
      </v-col>
          <v-col
            v-for="(item, index) in filteredProducts.filter(product => product.stateCode === 'Active' && product.categories.every(category => category.stateCode === 'Active'))"
            :key="index"
            cols="3"
            class="item-box"
            :id="`product-${item.id}`"
            @click="enterProduct(item.id)"
            :href="`/product/${item.id}`"
          >
          <router-link :to="`/categories/${$route.params.categoryId}/product/${item.id}`" style="text-decoration: none;">
            <!-- Display item data here using item properties -->
            <img
              v-if="item.images.some(image => image.type === 'Original')"
             :src="`https://api.rdprooff.ru/api/Image/GetResized?imageId=${item.images[0].id}&width=304&height=282&isKeepAspectRatio=true`"
              class="img-container"
              alt=""
            />
            <img
              v-else
              src="@/assets/img/image-default.png"
              class="img-container"
              alt="Not loading"
            />
            <p class="item-name">{{ item.name }}</p>
            <p class="item-country">{{ item.origin }}</p>
          </router-link>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import Header2 from "@/components/Header2.vue";
// import { mapGetters } from "vuex";

export default {
  name: "Categories",
  // props: {
  //   categoryId: {
  //     type: String, // или тип данных, который соответствует вашему айди
  //     required: true
  //   },
  //   categoryName: {
  //     type: String,
  //   },
  // },
  components: {
    Header2,
  },
//   beforeRouteEnter(to, from, next) {
//   const currentProductId = localStorage.getItem("currentProductId");
//   console.log(currentProductId);
//   if (to.params.categoryId && currentProductId) {
//     setTimeout(() => {
//         this.scrollIntoView(`product-${currentProductId}`)
//         // localStorage.removeItem("currentProductId"); // Удаляем сохраненный ID продукта после скроллинга
//     }, 1000); // Добавляем задержку в 1 секунду (или любое другое подходящее значение)
//   } else {
//     next();
//   }
// },
  data() {
    return {
      products: [],
      search: "",
      category: {},
      itemsPerPage: 1000,
      updatedCalled: false,
      isLoading: true,
      imgPathBase: "",
      // currentProductId:"",
    };
  },
  methods: {
    redirectToProduct() {
      // Используем $router.push() для перенаправления на страницу /product
      this.$router.push("/product");
    },
    getProductList() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .put("/api/Product/PagedList", {
          // pageNumber: this.currentPage,
          pageSize: this.itemsPerPage,
          filters: [
            {
              property: "categoryid",
              operator: "eq",
              value: this.$route.params.categoryId,
            },
          ],
          headers: {
            CurrentLanguage: locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.products = response.data;
          this.imgPathBase = process.env.VUE_APP_API_HOST;
          this.isLoading = false;
          // this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount; // Общее количество элементов
          // console.log(this.totalItems);
          // this.$store.commit('setCurrentCategory', this.products.categories)
        })
        .catch((error) => (this.error = error));
    },
    getCategory() {
      const locale = localStorage.getItem("app_locale");
      this.$http
        .get(`/api/Category/${this.$route.params.categoryId}`, {
          // pageNumber: this.currentPage,
          // pageSize: 100,
          headers: {
            CurrentLanguage: locale,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.category = response.data;
          this.$store.commit("setCurrentCategory", this.category);
          //   this.totalPages = response.data.totalPages; // Общее количество страниц
          // this.totalItems = response.data.totalCount;
        })
        .catch((error) => (this.error = error));
    },
    enterProduct(id) {
      this.$router.push({
        name: "ProductPage", // Имя дочернего маршрута (определено в маршрутах)
        params: {
          categoryId: this.$route.params.categoryId, // Передайте параметры родительского маршрута
          productId: id, // Передайте параметр productId
        },
      });
      localStorage.setItem("currentProductId", id);
      this.isScrollExecuted === false;
    },
    scrollIntoView(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
  },
//   updated() {
//   if (!this.updatedCalled) {
//     const currentProductId = localStorage.getItem("currentProductId");
//     console.log(currentProductId);
//     if (this.$route.params.categoryId) {
//       this.scrollIntoView(`product-${currentProductId}`);
//     }
//     this.updatedCalled = true; // Пометить, что метод уже был вызван
//     localStorage.removeItem("currentProductId");
//   }
// },
  created() {
    // Получение параметра запроса id
    // const curId = localStorage.getItem("categoryId");
    // // Если локаль найдена в локальном хранилище, установите ее
    // if (curId) {
    //   this.curId = curId;
    // }
    // console.log(this.categoryId);
    this.getCategory();
    this.getProductList();
  },
  mounted() {
},
  computed: {
    filteredProducts() {
      if (!this.products.items) {
        return [];
      }
      return this.products.items.filter((product) => {
        return (
          product.name.toLowerCase().includes(this.search.toLowerCase()) ||
          product.origin.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    currentLocale() {
      return this.$i18n.locale; // Получаем текущую локаль из хранилища Vuex
    },
    // ...mapGetters({
    //   category:"getCurrentCategory",
    // }),
  },
  watch: {
    "$route.params": {
      immediate: true, // Обработать изменения параметров маршрута сразу после создания компонента
      handler() {
        // Выполняйте действия при изменении параметров маршрута
        this.getProductList();
        this.getCategory();
      },
      deep: true, // Следите за изменениями во вложенных свойствах $route.params
    },
    currentLocale: {
      handler() {
        // Выполняйте запросы к API при изменении локали
        this.getProductList(); // Передаем новую локаль в функцию для обновления данных
        this.getCategory(); // Передаем новую локаль в другую функцию для обновления данных
      },
      immediate: true, // Вызывать обработчик сразу после создания компонента
    },
  },
};
</script>
  
  <style scoped>
  .loading-message {
    text-align: center;
  }
.no-products-message {
  color: var(--07, #000);
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
}
.horizontal-line {
  width: 100%;
  height: 1px;
  margin-top: 19px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 19px;
  background: var(--05, #dae3ea);
}
.img-container {
  object-fit: cover;
  width: 100%;
}
.item-box-mobile {
  text-align: center;
}
.item-country-mobile {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 8px;
}
.item-name-mobile {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-img-mobile {
  /* width: 124px; */
  height: 155px;
}
.items-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 118px;
}
.category-header-mobile {
  margin-top: 19px;
  margin-bottom: 33px;
}
.search-component__input-mobile {
  border-radius: 100px;

  border: 1px solid var(--Gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.search-component__icon-mobile {
  position: absolute;
  top: 10.5px;
  right: 15px;
}
.search-component-mobile {
  max-width: 606px;
  width: 100%;
  height: 45px;
  /* margin: 0 auto; */
  display: flex;
  position: relative;
  flex-direction: row;
}
.category-title-mobile {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 19px;
}
.distributor-container-mobile {
  padding-left: 16px;
  padding-right: 16px;
}
.item-box {
  transition: transform 0.2s ease; /* Добавляем анимацию сглаживания для свойства transform */
}

.item-box:hover {
  transform: scale(1.1);
  cursor: pointer; /* Увеличиваем элемент при наведении в 1.1 раза (10% увеличение) */
}
.item-box {
  text-align: center;
}
.item-country {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 10px;
}
.item-name {
  color: var(--01, #603725);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.items-container {
  display: flex;
  margin-top: 50px;
  margin-bottom: 177px;
  width: 1310px;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
.search-component__input {
  border-radius: 100px;

  border: 1px solid var(--Gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 10.5px;
  right: 15px;
}
.search-component {
  max-width: 606px;
  width: 100%;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.category-title {
  color: var(--01, #603725);
  font-family: Montserrat;
  font-size: 35px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
  width: 100%; /* Распределение ширины 50% для h1 */
}
.distributor-container {
  display: flex;
  margin-top: 0px;
  margin-bottom: 69px;
  width: 1334px;
  padding: 12px;
  margin-right: auto;
  margin-left: auto;
}
</style>
  
  